<template>
  <v-container fluid style="margin-top: 10px; margin-left: 5px;">

    <!-- Grid modules -->
    <v-card flat tile>
      <div :class="['grid-menu', gridMenuClass]">
        <div class="no-gutters row">
          <div
              :class="[$vuetify.breakpoint.smAndDown ? 'pa-0 px-0 ma-0 mx-0' : '', gridColClass]" v-bind:style="{
                    'width' : $vuetify.breakpoint.smAndDown? '42px !important' : ''
                    }" v-for="module in sortedTemplateModules.filter(moduleCheck => moduleCheck.isExtra === 0)"
              :key="module.id">

            <ButtonsItemInfo v-if="module.id === 1" :pos-type="posType"/>

            <ButtonsSearchItems v-if="module.id === 2" :pos-type="posType"/>

            <ButtonsDiscount
                v-if="module.id === 3 && $store.getters['permissions/checkPermission']('allowGiveDiscount')"
                :pos-type="posType"/>

            <ButtonsFreetext v-if="module.id === 4" :pos-type="posType"/>

            <ButtonsVoucher
                v-if="module.id === 5 && user.userID !== 101 && $store.getters['permissions/checkModule'](31) && $store.getters['permissions/checkPermission']('issueVouchers')"
                :pos-type="posType"></ButtonsVoucher>

            <ButtonsSaveOrder v-if="module.id === 6 && user.userID !== 101 && posType.toLowerCase().includes('retail')"
                              :pos-type="posType"></ButtonsSaveOrder>

            <ButtonsOpenSavedOrder v-if="module.id === 7 && user.userID !== 101 && posType.toLowerCase().includes('retail')"
                                   :pos-type="posType"></ButtonsOpenSavedOrder>

            <ButtonsGiftCard
                v-if="module.id === 8 && user.userID !== 101 && $store.getters['permissions/checkModule'](31) && $store.getters['permissions/checkPermission']('issueVouchers')"
                :pos-type="posType"></ButtonsGiftCard>


            <buttons-zmyle
                v-if="module.id === 28 && user.userID !== 101 && $store.getters['permissions/checkModule'](58)"
                :pos-type="posType"/>


            <ButtonsTableMerge v-if="module.id === 9 && posType === 'gastro'" :pos-type="posType"/>

            <ButtonsCourse
                v-if="module.id === 10 && posType === 'gastro' && $store.getters['permissions/checkModule'](30)"
                :pos-type="posType"/>

            <ButtonsPayBar
                v-if="module.id === 11 && $store.getters['permissions/checkPermission']('allowDirectSale') && checkPaymentType(1)"
                :pos-type="posType"
                :disabled="isBooking || !canPay"></ButtonsPayBar>

            <ButtonsPayEC
                v-if="module.id === 12 && $store.getters['permissions/checkPermission']('allowDirectSale') && checkPaymentType(3)"
                :pos-type="posType"
                :disabled="isBooking || !canPay"></ButtonsPayEC>

            <ButtonsPayCreditCard
                v-if="module.id === 13 && $store.getters['permissions/checkPermission']('allowDirectSale') && checkPaymentType(9)"
                :pos-type="posType"
                :disabled="isBooking || !canPay"></ButtonsPayCreditCard>

            <ButtonsPrintDailyReport v-if="module.id === 14 && user.userID !== 101 && $store.getters['permissions/checkPermission']('dailyReport')"
                                     :pos-type="posType"></ButtonsPrintDailyReport>

            <ButtonsPrintXReport v-if="module.id === 15 && user.userID !== 101 && $store.getters['permissions/checkPermission']('dailyReport')"
                                 :pos-type="posType"></ButtonsPrintXReport>

            <ButtonsPrintUsersReport v-if="module.id === 16 && user.userID !== 101 && $store.getters['permissions/checkPermission']('dailyUserReportPrinting')"
                                     :pos-type="posType"></ButtonsPrintUsersReport>

            <ButtonsStorno v-if="module.id === 18 && canVoidItems"
                           :pos-type="posType"/>

            <ButtonsTableSplit v-if="module.id === 19 && posType === 'gastro'" :pos-type="posType"></ButtonsTableSplit>

            <ButtonsPager v-if="module.id === 20 && posType === 'gastro'" :pos-type="posType"/>

            <ButtonsPrintInterimBill v-if="module.id === 21" :pos-type="posType"></ButtonsPrintInterimBill>

            <ButtonsOpenCashDrawer
                v-if="module.id === 23 && $store.getters['permissions/checkPermission']('allowOpenCashDrawer')"
                :pos-type="posType"></ButtonsOpenCashDrawer>

            <butttons-gift-card-scanner v-if="module.id === 24" :pos-type="posType"/>

            <buttons-cash-book
                v-if="module.id === 25 && $store.getters['permissions/checkPermission']('accounting_displayCashbook') && $store.getters['permissions/checkModule'](18) "
                :pos-type="posType"/>

            <ButtonsIncrease
                v-if="module.id === 26 && $store.getters['permissions/checkPermission']('allowChangePrice')"
                :pos-type="posType"/>

            <ButtonsReprintLastInvoice v-if="module.id === 30"/>

            <ButtonsEventbee v-if="$store.getters['permissions/checkModule'](56) && module.id === 32 && posType !=='wholesaleRetail'" :pos-type="posType"/>
          </div>

          <div
              :class="[$vuetify.breakpoint.smAndDown ? 'pa-0 px-0 ma-0 mx-0' : '', gridColClass]" v-bind:style="{
                    'width' : $vuetify.breakpoint.smAndDown? '42px !important' : ''
                    }">
            <ButtonsZipCode v-if="pos.gastro.isDeliveryService && posType === 'gastro'"/>
          </div>

          <div
              :class="[$vuetify.breakpoint.smAndDown ? 'pa-0 px-0 ma-0 mx-0' : '', gridColClass]" v-bind:style="{
                    'width' : $vuetify.breakpoint.smAndDown? '42px !important' : ''
                    }">
            <ButtonsDeliveryPayment v-if="pos.gastro.isDeliveryService && posType === 'gastro'"/>
          </div>

          <div
              :class="[$vuetify.breakpoint.smAndDown ? 'pa-0 px-0 ma-0 mx-0' : '', gridColClass]" v-bind:style="{
                    'width' : $vuetify.breakpoint.smAndDown? '42px !important' : ''
                    }">
            <ButtonsDeliveryServiceTime v-if="pos.gastro.isDeliveryService && posType === 'gastro'"/>
          </div>


          <ButtonsDeliveryPickup
              v-if="pos.gastro.isDeliveryService && sortedTemplateModules.filter(module=>module.id===27).length>0 && posType === 'gastro'"
              :pos-type="posType"/>

        </div>
      </div>
    </v-card>


    <!-- Extra Modules not in grid -->
    <div v-for="module in sortedTemplateModules.filter(moduleCheck => moduleCheck.isExtra === 1)" :key="module.id">
      <ButtonsEuroNotes v-if="module.id === 17 && checkPaymentType(1)" :pos-type="posType" :disabled="isBooking"></ButtonsEuroNotes>
      <Numpad v-if="module.id === 22" ref="numpadRef" style="margin-top: 10px;" :pos-type="posType" show-e-a-n-field show-control-buttons/>

      <ButtonsAddGFOrV v-if="module.id === 31" @addGFOrV="addGFOrV"/>

      <!-- go to pickup btn -->
      <buttons-go-pickup
          v-if="$store.getters['permissions/checkModule'](13) && module.id === 29 && posType === 'gastro' && !isDeliveryService"/>
    </div>

  </v-container>
</template>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}


</style>

<script>
// @TODO needs optimisation a lot of useless checks

import ButtonsStorno from "./buttons/ButtonsStorno";
import ButtonsItemInfo from "./buttons/ButtonsItemInfo";
import ButtonsDiscount from "./buttons/ButtonsDiscount";
import ButtonsSearchItems from "./buttons/ButtonsSearchItems";
import ButtonsFreetext from "./buttons/ButtonsFreetext";
import Numpad from "./Numpad";
import ButtonsTableMerge from "./buttons/ButtonsTableMerge";
import ButtonsCourses from "./buttons/ButtonsCourses";
import ButtonsSaveOrder from "./buttons/ButtonsSaveOrder";
import ButtonsOpenSavedOrder from "./buttons/ButtonsOpenSavedOrder";
import ButtonsGiftCard from "./buttons/ButtonsGiftCard";
import ButtonsVoucher from "./buttons/ButtonsVoucher";
import ButtonsPayBar from "./buttons/ButtonsPayBar";
import ButtonsPayEC from "./buttons/ButtonsPayEC";
import ButtonsPayCreditCard from "./buttons/ButtonsPayCreditCard";
import ButtonsEuroNotes from "./buttons/ButtonsEuroNotes";
import ButtonsPrintDailyReport from "./buttons/ButtonsPrintDailyReport";
import ButtonsPrintXReport from "./buttons/ButtonsPrintXReport";
import ButtonsPrintUsersReport from "./buttons/ButtonsPrintUsersReport";
import ButtonsTableSplit from "./buttons/ButtonsTableSplit";
import ButtonsPager from "./buttons/ButtonsPager";
import ButtonsCourse from "./buttons/ButtonsCourse";
import ButtonsCashBook from "./buttons/ButtonsCashBook";
import ButtonsIncrease from "./buttons/ButtonsIncrease";
import ButtonsReprintLastInvoice from "./buttons/ButtonsReprintLastInvoice";
import ButtonsAddGFOrV from "./buttons/ButtonsAddGFOrV";

import ButtonsPrintInterimBill from "@/components/pos/buttons/ButtonsPrintInterimBill";

import {mapGetters, mapState} from "vuex";

import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faBell,
  faBoxes,
  faBoxFragile,
  faCalendarAlt,
  faCommentAltLines,
  faCreditCard,
  faEject,
  faEuroSign,
  faFileInvoice,
  faFileInvoiceDollar,
  faGiftCard,
  faGifts,
  faIdCard,
  faInboxIn,
  faInboxOut,
  faInfoSquare,
  faMoneyCheck,
  faPercent,
  faPrint,
  faRandom,
  faRepeat,
  faSearch,
  faTrash,
  faUserClock,
  faUserFriends,
  faChevronDown,
  faChevronUp,
  faTicketAlt,
    faTags,
    faTag
} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import ButttonsGiftCardScanner from "@/components/pos/buttons/ButtonsGiftCardScanner";
import ButtonsOpenCashDrawer from "@/components/pos/buttons/ButtonsOpenCashDrawer";
import ButtonsSuite8Search from "@/components/pos/buttons/ButtonsSuite8Search";
import ButtonsDeliveryPickup from "@/components/pos/buttons/ButtonsDeliveryPickup";
import ButtonsZipCode from "@/components/pos/buttons/ButtonsZipCode";
import ButtonsDeliveryPayment from "@/components/pos/buttons/ButtonsDeliveryPayment";
import ButtonsDeliveryServiceTime from "./buttons/ButtonsDeliveryServiceTime";
import ButtonsZmyle from "@/components/pos/buttons/ButtonsZmyle";
import ButtonsGoPickup from "@/components/pos/buttons/ButtonsGoPickup";
import bookingevents from "../../mixins/pos/bookingevents";
import ButtonsEventbee from "./buttons/ButtonsEventbee";

library.add(
    faSearch,
    faTrash,
    faInfoSquare,
    faPercent,
    faCommentAltLines,
    faInboxOut,
    faInboxIn,
    faCalendarAlt,
    faBoxes,
    faUserClock,
    faBell,
    faBoxFragile,
    faPrint,
    faGiftCard,
    faIdCard,
    faEject,
    faMoneyCheck,
    faEuroSign,
    faCreditCard,
    faRepeat,
    faRandom,
    faFileInvoice,
    faGifts,
    faUserFriends,
    faChevronDown,
    faChevronUp,
    faFileInvoiceDollar,
    faTicketAlt,
    faTags,
    faTag
);

export default {
  name: "Buttons",

  props: {
    posType: String
  },
  mixins:[bookingevents],
  components: {
    ButtonsEventbee,
    ButtonsGoPickup,
    ButtonsZmyle,
    ButtonsDeliveryServiceTime,
    ButtonsDeliveryPayment,
    ButtonsZipCode,
    ButtonsDeliveryPickup,
    ButtonsSuite8Search,
    ButtonsOpenCashDrawer,
    ButttonsGiftCardScanner,
    ButtonsPrintInterimBill,
    ButtonsCourse,
    ButtonsCashBook,
    ButtonsPager,
    ButtonsTableSplit,
    ButtonsPrintUsersReport,
    ButtonsPrintXReport,
    ButtonsPrintDailyReport,
    ButtonsEuroNotes,
    ButtonsPayCreditCard,
    ButtonsPayEC,
    ButtonsPayBar,
    ButtonsVoucher,
    ButtonsGiftCard,
    ButtonsOpenSavedOrder,
    ButtonsSaveOrder,
    ButtonsCourses,
    ButtonsTableMerge,
    Numpad,
    'font-awesome-icon': FontAwesomeIcon,
    ButtonsFreetext,
    ButtonsSearchItems,
    ButtonsDiscount,
    ButtonsItemInfo,
    ButtonsStorno,
    ButtonsIncrease,
    ButtonsReprintLastInvoice,
    ButtonsAddGFOrV
  },

  computed: {
    ...mapState([
      'user',
      'payments',
      'pos',
      'api',
      'posButtonsTemplates'
    ]),
    ...mapGetters({
      isDelivery: 'pos/gastro/getDelivery',
      isDeliveryService: 'pos/gastro/isDeliveryService',
      hasGiftCardAndSurpassMin: 'pos/hasGiftCardAndSurpassMin',
      bookedItemsCount: 'pos/bookedItemsCount',
    }),
    canPay(){
      if(!this.hasGiftCardAndSurpassMin(this.posType)){
        return false;
      }

      if(this.bookedItemsCount(this.posType)<1){
        return false;
      }

      return true;
    },
    canVoidItems() {
      //ordered items
      if (this.$store.getters['pos/gastro/selectedOrderedItem'] !== null) {
        return this.$store.getters['permissions/checkPermission']('allowVoidItems');
      }      //open items
      else if (this.$store.getters['pos/gastro/selectedOpenItem'] !== null) {
        return this.$store.getters['permissions/checkPermission']('allowImmediateVoidItems');
      } else if (this.$store.getters['pos/retail/orderedItems'] !== null) {
        return this.$store.getters['permissions/checkPermission']('allowVoidItems');
      } else {
        return false;
      }
    },
    buttonsTemplate() {
      if (this.posButtonsTemplates.templates.length === 0)
        return null;

      if (this.pos.gastro.isDeliveryService)
        if (this.posButtonsTemplates.templates.hasOwnProperty("delivery"))
          return this.posButtonsTemplates.templates.delivery;

      if (this.posType === "gastro")
        if (this.posButtonsTemplates.templates.hasOwnProperty("gastro"))
          return this.posButtonsTemplates.templates.gastro;

      if (this.posType === "retail")
        if (this.posButtonsTemplates.templates.hasOwnProperty("retail"))
          return this.posButtonsTemplates.templates.retail;

      if (this.posType === "wholesaleRetail")
        if (this.posButtonsTemplates.templates.hasOwnProperty("retail"))
          return this.posButtonsTemplates.templates.retail;

      return null;
    },
    gridColClass() {
      if (this.buttonsTemplate === null)
        return 'col-md-4';

      return 'col-md-' + this.buttonsTemplate.grid_size;
    },
    gridMenuClass() {
      if (this.buttonsTemplate === null)
        return 'grid-menu-3col';

      return 'grid-menu-' + (12 / this.buttonsTemplate.grid_size) + 'col';
    },
    sortedTemplateModules() {
      if (this.buttonsTemplate === null) {
        let fakeModules = [];

        /*
        for(let i = 1; i <= 22; i++) {
            fakeModules.push({
                id: i,
                isExtra: (i === 17 ? 1 : (i === 22 ? 1 : 0))
            })
        }
        */

        return fakeModules;
      }

      let modules = this._.cloneDeep(this.buttonsTemplate.modules);
      return modules.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0));
    }
  },

  data() {
    return {
      showButtonText: false,
      loadingSettings: false,
      delivery: true,
      settings: {
        isClosed: 0,
        offersDelivery: 0,
        offersPickUp: 0
      },
    }
  },

  mounted() {

  },

  methods: {
    addGFOrV(prefix) {
      this.$refs.numpadRef[0].addGFOrV(prefix)
    },
    checkPaymentType(paymentID) {
      let payment = this.payments.payments.find((payment) => payment.paymentID === paymentID);

      if (!payment)
        return false;

      return true;
    },
  }
}
</script>
<style scoped>
button:disabled, button[disabled] {
  opacity: 0.1 !important;
}
</style>
