<template>
    <v-tooltip
      :value="show"
      :position-x="x"
      :position-y="y"
      absolute
      top
      :color="color"
    >
    <div v-if="rowNumber">{{$t('eventbee.lang_row')}}: {{ rowNumber }}</div>
    <div v-if="tableName">{{$t('generic.lang_table')}}: {{ tableName }}</div>
    <div>{{$t('eventbee.lang_seat')}}: {{ seatNumber }}</div>
    </v-tooltip>
  </template>
  
  <script>
  export default {
    name: 'SeatTooltip',
    props: {
      show: Boolean,
      color: String,
      x: Number,
      y: Number,
      rowNumber: [String, Number],
      tableName: [String, Number],
      seatNumber: [String, Number],
    },
  }
  </script>