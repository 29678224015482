<template>
  <div>
    <!-- Storno -->
    <!-- desktop version -->
    <button v-if="showStornoButton && this.$vuetify.breakpoint.mdAndUp"
            class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger"
            @click="openDialog">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal', 'trash']"/>
      </i><span v-if="showButtonText">Storno</span>
    </button>

    <!-- mobile version -->
    <button v-else-if="this.$vuetify.breakpoint.smAndDown && showStornoButton  && ordered"
            class="btn-danger btn-square btn btn-transition"
            style="height: 59px !important;width: 59px !important;"
            @click="openDialog">

      <font-awesome-icon :icon="['fal', 'trash']" class="mt-1 mb-1 btn-icon-wrapper mx-auto ma-auto"
                         style="font-size: 24px !important;"/>
    </button>


    <!-- Delete -->

    <!-- mobile version -->
    <button v-else-if="showDeleteButton  && this.$vuetify.breakpoint.smAndDown"
            class="btn-success btn-square btn btn-transition"
            style="height: 59px !important;width: 59px !important;"
            @click="isOpenItem = true;deleteOpenItem()">

      <font-awesome-icon :icon="['fal', 'trash']" class="mt-1 mb-1 btn-icon-wrapper mx-auto ma-auto"
                         style="font-size: 20px !important;"/>
    </button>


    <!-- desktop version -->
    <button v-else-if="showDeleteButton   && this.$vuetify.breakpoint.mdAndUp"
            class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger"
            @click="isOpenItem = true;deleteOpenItem()">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal', 'trash']"/>
      </i><span v-if="showButtonText">{{ this.$t('generic.lang_delete') }}</span>
    </button>


    <VoidReasons :selected-item="selectedItem" :show-dialog="showVoidReasonsDialog" @closeDialog="closeDialog"
                 @selectVoidReason="voidItem" :skip-amount-selection="checkIfAmountSelectionSkip"/>

    <!-- password dialog  -->
    <v-dialog max-width="600" v-model="showPasswordDialog">
      <v-card>
        <v-card-title>{{ $t('generic.lang_enterStornoPassword') }}</v-card-title>
        <v-card-text>
          <v-form v-model="valid" lazy-validation ref="passwordForm">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field @keyup.enter="checkPassword" autofocus
                              :rules="[v => !!v , v => this.validPassword || $t('generic.lang_pleaseEnterValidPassword')]"
                              type="password" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              @focus="showTouchKeyboard" v-model="password" dense outlined/>
              </v-col>
              <v-col cols="12">
                <v-btn @click="checkPassword" :disabled="!valid" color="primary" depressed block>
                  {{ this.$t('generic.lang_next') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}

</style>

<script>
import VoidReasons from "../VoidReasons";
import mixin from "../../../mixins/KeyboardMixIns";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faTrash} from '@fortawesome/pro-light-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {mapState,mapGetters} from "vuex";

library.add(
    faTrash
);

export default {
  mounted() {
    if (this.posType === "gastro" && this.$vuetify.breakpoint.mdAndUp)
      if (this.pos.gastro.selectedOpenItem !== null && this.item !== undefined && this.item !== this.pos.gastro.selectedOpenItem && !this.ordered) {
        this.$store.dispatch("pos/gastro/selectInvoiceOpenItem", this.item);
      } else if (this.ordered) {
        this.$store.dispatch("pos/gastro/selectInvoiceOrderedItem", this.item);
      }
  },
  name: "ButtonsStorno",

  components: {
    'font-awesome-icon': FontAwesomeIcon,
    VoidReasons
  },


  props: {
    item: {
      type: Object
    },
    posType: String,
    showButtonText: Boolean,
    ordered: Boolean
  },
  mixins: [mixin],
  data() {
    return {
      showVoidReasonsDialog: false,
      showPasswordDialog: false,
      password: '',
      valid: false,
      isOpenItem: false,
      reject: null,
      resolve: null
    }
  },

  computed: {
    ...mapState([
      'pos',
      'cashierIDs',
      'api'
    ]),
    ...mapGetters({
      'currentParty':"pos/gastro/currentParty"
    }),
    askForVoidPassword() {
      //search for the current cashier
      let current = this.cashierIDs.cashiers.find((cashier) => cashier.id === this.api.auth.cashierID);
      if (current)
        return current.askForVoidPassword === 1;
      else return false
    },
    askForVoidOpenItemsPassword() {
      //search for the current cashier
      let current = this.cashierIDs.cashiers.find((cashier) => cashier.id === this.api.auth.cashierID);
      if (current)
        return current.askForVoidOpenItemsPassword === 1;
      else return false
    },
    validPassword() {
      //search for the current cashier
      let current = this.cashierIDs.cashiers.find((cashier) => cashier.id === this.api.auth.cashierID);
      if (current)
        return current.voidPassword === this.password;
      else return false
    },
    showDeleteButton() {
      if (this.posType === "gastro") {
        if(this.currentParty?.hasOwnProperty('is_payed') && this.currentParty.is_payed){
          return false;
        }
        if (this.pos.gastro.selectedOpenItem !== null || this.$vuetify.breakpoint.smAndDown) {
          return true;
        }
      }
      return false;
    },
    showStornoButton() {
      if (this.posType === "gastro") {
        if(this.currentParty?.hasOwnProperty('is_payed') && this.currentParty.is_payed){
          return false;
        }
        if (this.pos.gastro.selectedOrderedItem !== null || this.$vuetify.breakpoint.smAndDown) {
          return true;
        }
      } else if (this.posType === "retail" || this.posType === "wholesaleRetail") {
        return true;
      }
      return false;
    },
    selectedItem() {
      if (this.posType === "retail") {
        return this.pos.retail.selectedItem;
      }
      
      if (this.posType === "wholesaleRetail") {
        return this.pos.wholesaleRetail.selectedItem;
      }

      if (this.posType === "gastro") {
        if (this.pos.gastro.selectedOpenItem !== null) {
          return this.pos.gastro.selectedOpenItem;
        } else if (this.pos.gastro.selectedOrderedItem !== null) {
          return this.pos.gastro.selectedOrderedItem;
        }
      }

      return null;
    },
    checkIfAmountSelectionSkip() {
      if (this.selectedItem === null || this.selectedItem === undefined)
        return false;

      return !Number.isInteger(this.selectedItem.amount);
    }
  },
  methods: {
    openAsyncPasswordDialog() {
      return new Promise((resolve, reject) => {
        this.showPasswordDialog = true;
        this.resolve = resolve;
        this.reject = reject;
        if (this.$refs.passwordForm)
          this.$refs.passwordForm.validate();
      })
    },
    checkPassword() {
      if (this.validPassword) {
        if (this.selectedItem === null)
          return;

        if (this.selectedItem.isVoid)
          return;

        if (this.isOpenItem) {
          this.resolve(true);
        } else {
          this.showVoidReasonsDialog = true;
          this.showPasswordDialog = false;
          this.$refs.passwordForm.reset();
        }
      }
    },
    openDialog() {
      if(this.$vuetify.breakpoint.smAndDown){
        if (this.posType === 'retail'){
          this.$store.commit("pos/retail/selectInvoiceItem", this.item);
        }else if (this.posType === 'wholesaleRetail'){
          this.$store.commit("pos/wholesaleRetail/selectInvoiceItem", this.item);
        }else if(this.item.randomPositionID !== this.selectedItem?.randomPositionID) {
          if (this.ordered) {
            this.$store.commit("pos/gastro/selectInvoiceOrderedItem", this.item);
          } else{
            this.$store.commit("pos/gastro/selectInvoiceOpenItem", this.item);
          }
        }
      }
      if (this.selectedItem === null)
        return;

      if (this.selectedItem.isVoid)
        return;

      if (this.askForVoidPassword) {
        this.showPasswordDialog = true;
        this.$refs.passwordForm.validate();
      } else this.showVoidReasonsDialog = true;
    },
    closeDialog() {
      this.loading = false;
      this.disabled = false;

      this.showVoidReasonsDialog = false;
    },
    voidItem(data) {

      if(this.posType==="gastro"){
        if(this.currentParty?.hasOwnProperty('is_payed') && this.currentParty.is_payed){
          return ;
        }
      }

      this.$store.dispatch("pos/stornoItem", {
        id: data.id,
        posType: this.posType,
        voidReason: data.voidReason,
        amount: data.amount
      }).then(() => {
        this.loading = false;
        this.disabled = false;

        this.showVoidReasonsDialog = false;
      }).finally(()=>{
        if(this.$vuetify.breakpoint.smAndDown){
          this.$emit('closeLeftReveal', this.item);
        }
      })
    },
    deleteItem() {
      if (this.posType === "gastro") {
        if(this.currentParty?.hasOwnProperty('is_payed') && this.currentParty.is_payed){
          return;
        }
        if(this.$vuetify.breakpoint.smAndDown && this.item.randomPositionID !== this.selectedItem?.randomPositionID) {
          if (this.ordered) {
            this.$store.commit("pos/gastro/selectInvoiceOrderedItem", this.item);
          } else{
            this.$store.commit("pos/gastro/selectInvoiceOpenItem", this.item);
          }
        }
      }

      this.loading = true;
      this.disabled = true;

      this.$store.dispatch("pos/deleteItem", {
        posType: this.posType
      }).then(() => {
        this.loading = false;
        this.disabled = false;
      }).finally(()=>{
        if(this.$vuetify.breakpoint.smAndDown){
          this.$emit('closeLeftReveal', this.item);
        }
      })
    },
    async deleteOpenItem(){
        if (this.isOpenItem && this.askForVoidPassword && this.askForVoidOpenItemsPassword) {
          await this.openAsyncPasswordDialog().then(() => {
            this.showPasswordDialog = false;
            this.$refs.passwordForm.reset();
            this.deleteItem();
          });
        } else {
          this.deleteItem();
        }

        this.isOpenItem = false;
    }
  }
}
</script>
